import Alpine from "alpinejs"

import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'
import persist from '@alpinejs/persist'

declare global {
    interface Window {
        Alpine: typeof Alpine
    }
}

window.Alpine = Alpine
Alpine.plugin(focus)
Alpine.plugin(collapse)
Alpine.plugin(persist)

/**
 * Alpine.js navigation component
 */
Alpine.data('navigate', () => ({

    active: false,

    openMenu: false,

    openSearch: false,

    init() {

        this.$watch('openMenu', (value: boolean) => {
            this.active = value
        })

        this.$watch('openSearch', (value: boolean) => {
            this.active = value
        })

        const body = document.body
        if (body) {
            /* Set body overflow when menu is open */
            this.$watch('active', (value: boolean) => {
                if (value) {
                    body.classList.add('overflow-hidden')
                } else {
                    body.classList.remove('overflow-hidden')
                }
            })
        }

    },

    /* Toggle the nav menu */
    toggleMenu() {
        this.openSearch = false
        // Set menu height
        this.$refs.menu.style.minHeight = 'calc(100vh - ' + this.$refs.navbar.offsetHeight + 'px)'
        this.openMenu = ! this.openMenu
    },

    /* Toggle the search menu */
    toggleSearch() {
        this.openMenu = false
        this.$refs.search.style.minHeight = 'calc(100vh - ' + this.$refs.navbar.offsetHeight + 'px)'
        this.openSearch = ! this.openSearch
    },

}))

/**
 * Alpine.js filter component
 */
Alpine.data('directoryFilter', (props: { selectedAlpha: string, selectedDepartment: string, selectedDepartmentTitle: string }) => ({
    dropdownOpen: false,

    selectedAlpha: props.selectedAlpha,
    selectedDepartment: props.selectedDepartment,
    selectedDepartmentTitle: props.selectedDepartmentTitle,

    buildQuery() {
        let query = [];

        if (this.selectedAlpha !== '') {
            query.push('q=' + this.selectedAlpha)
        }  if (this.selectedDepartment !== '') {
            query.push('dept=' + this.selectedDepartment)
        }

        if (query.length > 0) {
            return '/about/directory?' + query.join('&')
        } else {
            return '/about/directory'
        }
    },

    init() {
        this.$watch('selectedAlpha', () => {
            window.location.href = this.buildQuery()
        });

        this.$watch('selectedDepartment', () => {
            window.location.href = this.buildQuery()
        })
    }
}))

// <iframe src="https://libraryh3lp.com/html/chat-box.html?queue=landmarklibrary&skin=16823&email=library@landmark.edu" frameborder="1" style="border: 1px solid #888; width: 310px; height: 420px;"></iframe>

/**
 * Alpine.js chat box component
 */
Alpine.data('chatBox', function(props: { container: string }) {
    return {
        url: 'https://libraryh3lp.com/html/chat-box.html?queue=landmarklibrary&skin=16823&email=library@landmark.edu',
        container: props.container,

        chatActive: Alpine.$persist(false).using(sessionStorage),

        init() {

            if (this.chatActive) {
                this.openChat()
            }

            this.$watch('chatActive', (value: any) => {
                if (value) {
                    this.openChat()
                } else {
                    this.closeChat()
                }
            })
        },

        openChat() {
            const element = document.getElementById(this.container)
            if (element) {
                // Show container
                element.classList.remove('hidden')

                // Create iframe if not already present
                if (element.querySelector('iframe') === null) {
                    const iframe = document.createElement('iframe')
                    iframe.src = this.url
                    iframe.className = "w-full"

                    let childrenHeight = 0
                    for (const child of Array.from(element.children) as HTMLElement[]) {
                        childrenHeight += child.offsetHeight
                    }

                    // Set the height of the iframe
                    iframe.height = String(element.clientHeight - childrenHeight) + 'px'

                    element.appendChild(iframe)
                }
            }
        },

        closeChat() {
            const element = document.getElementById(this.container)
            if (element) {
                // Hide container
                element.classList.add('hidden')
            }
        }
    }
})


Alpine.start()

import Glide from "@glidejs/glide";

document.addEventListener('DOMContentLoaded', () => {

    /**
     * Create responsive carousels at small screens
     * @param element
     */
    const setupResponsiveCarousel = (element: HTMLElement) => {
        return new Glide(element, {
            type: 'carousel',
            startAt: 0,
            perView: 1,
            breakpoints: {

            }
        }).mount()
    }

    /**
     * Check screen size and setup response carousels
     */
    const checkScreenSize = () => {

        const responsiveCarousels: NodeListOf<HTMLElement> = document.querySelectorAll('.glide-responsive')
        const responsiveGlides: Glide[] = []

        if (window.innerWidth <= 728) {
            if (responsiveCarousels.length > 0) {
                responsiveCarousels.forEach((element) => {
                    let glide = setupResponsiveCarousel(element)
                    responsiveGlides.push(glide)
                })
            }
        } else {
            responsiveGlides.forEach( (glide) => {
                glide.destroy()
            })
        }
    }

    /**
     * Glide carousel
     */
    const carousels: NodeListOf<HTMLElement> = document.querySelectorAll('.glide-carousel')
    if (carousels.length > 0) {
        carousels.forEach( (element) => {
            new Glide(element, {
                type: 'carousel',
                startAt: 0,
                perView: 1
            }).mount()
        })
    }

    /**
     * Events
     */
    const events: NodeListOf<HTMLElement> = document.querySelectorAll('.glide-events')
    if (events.length > 0) {
        events.forEach( (element) => {
            new Glide(element, {
                type: 'carousel',
                startAt: 0,
                perView: 3,
                autoplay: 4000,
                peek: { before: 0, after: 100 },
                breakpoints: {
                    768: {
                        perView: 1,
                        peek: 0
                    }
                }
            }).mount()
        })
    }

    checkScreenSize()

})

/**
 * Handle freeform forms
 */
const forms = document.querySelectorAll("[data-freeform-tailwind]")
forms.forEach(function (form) {
    // Styling for AJAX responses
    interface FreeformReadyEvent extends Event {
        form: HTMLFormElement
        freeform: {
            setOption: (option: string, value: string[]) => void
        }
        option: {
            errorClassBanner: string[]
            errorClassList: string[]
            errorClassField: string[]
            successClassBanner: string[]
        }
    }
    form.addEventListener("freeform-ready", ((event: FreeformReadyEvent)=> {
        let freeform = event.freeform
        freeform.setOption("errorClassBanner", ["bg-red-100", "border", "border-red-400", "font-bold", "text-red-700", "px-4", "py-3", "rounded", "relative", "mb-4"])
        freeform.setOption("errorClassList", ["errors", "text-red-500", "text-sm", "italic"])
        freeform.setOption("errorClassField", ["freeform-input", "freeform-error"])
        freeform.setOption("successClassBanner", ["bg-green-100", "border", "border-green-500", "font-bold", "text-green-700", "px-4", "py-3", "rounded", "relative", "mb-4"])
    }) as EventListener)

    // Hides other form field error messaging
    interface FreeformSubmitEvent extends Event {
        form: HTMLFormElement
        freeform: {
            setOption: (option: string, value: string[]) => void
        }
    }
    form.addEventListener("freeform-on-submit", ((event: FreeformSubmitEvent) => {
        const formId = event.form.getAttribute("data-id")
        forms.forEach(function (otherForm) {
            const otherFormId = otherForm.getAttribute("data-id")
            if (formId !== otherFormId) {
                otherForm.querySelectorAll("[data-field-errors]").forEach(element => element.remove())
                otherForm.querySelectorAll(".freeform-input").forEach(element => element.classList.remove("freeform-error"))
            }
        })
    }) as EventListener)
});
